@use '/styles/mixin/fonts.module.scss' as *;
.drive-home-compare-thumbnails {
  @apply w-full;
  @apply mb-4;

  &__header-section {
    @apply flex;
    @apply flex-col;
    @apply mb-8;
    @screen md {
      @apply flex-row;
      @apply justify-between;
      @apply items-end;
    }
    &__title {
      @include font-heading('mobile', 'h2');
      @apply mb-1;
      @screen md {
        @include font-pullquote('desktop');
        @apply m-0;
      }
    }
    &__link {
      @include font-subtext('desktop', 'medium');
    }
  }

  &__comparisons {
    @apply w-full;
    @apply flex;
    @apply flex-wrap;
    @apply justify-between;
  }

  &__compare-card-wrapper {
    @apply flex-1;
    @apply flex-wrap;
    @apply justify-center;
    @apply min-w-[296px];
    @apply py-2;
    @apply px-0;
    @media screen and (min-width: 632px) {
      @apply px-2;
      @apply max-w-[50%];
    }
    @screen lg {
      @apply justify-start;
    }
    @screen lg {
      &:first-child {
        @apply pl-0;
      }
      &:last-child {
        @apply pr-0;
      }
    }
  }
  &__compare-card {
    @apply m-auto;
  }
}
